import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { spfAction } from 'actions'

function* handleCheckSpf(action) {
  let { payload, meta, error } = yield call(api.checkSpf, action.payload)
  if (!error) {
    if (['not_included', 'unset', 'empty'].includes(payload.checkSpfResult)) {
      payload = new Error()
      meta.failMess = 'SPFレコードが設定されていません。'
    } else {
      meta.succMess = 'SPFレコードは正常です。'
    }
  }
  yield put(spfAction.checkSpfDone(payload, meta))
}

export const spfSaga = [
  takeLatest(spfAction.checkSpf, handleCheckSpf),
]

import { handleActions } from 'redux-actions'
import generateHandlerArgs, { preparedHandlers } from 'modules/reducerGenerator'
import { abandonedCampaignAction as action } from 'actions'

const overrideHandlers = {
  [action.submitSendAbandonedCampaignTestMail]: (state, action) => ({
    ...state,
    isSendingTestMail: true,
  }),
  [action.submitSendAbandonedCampaignTestMailDone]: (state, action) => ({
    ...state,
    isSendingTestMail: false,
    processResult: action.error ? action.payload : 'SUCCESS',
  }),
  [action.submitEnableAbandonedCampaignDone]: preparedHandlers.submitUpdateObjectDone(true),
}

const overrideInitial = {
  isSendingTestMail: false,
}

const { handler, initial } = generateHandlerArgs(action, 'abandonedCampaign', overrideHandlers, overrideInitial)

export const abandonedCampaign = handleActions(handler, initial)

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import {
  Alert, Button, FormGroup, Input, FormText, UncontrolledCollapse, UncontrolledTooltip,
} from 'reactstrap'
import {
  Loading, FieldSingle, FieldGroup, Label, InputGroupAddon, MailPreviewButton, HelpIcon, HintIcon,
} from 'lib/components'
import { completedCampaignConstant as ccc } from 'config/constants'
import { arraysToObject } from 'lib/utils'
import { completedCampaignAction, spfAction, mailPreviewAction } from 'actions'
import CampaignMailCodeHelp from 'components/parts/campaign/CampaignMailCodeHelp'
import CampaignHtmlFields from 'components/parts/campaign/CampaignHtmlFields'
import ManualLink from 'components/parts/manual/ManualLink'

// constants
const completedTypes = arraysToObject(ccc.completedTypes, 2, 1)
const followingCompletedTypes = [completedTypes.notRegisterCompletion, completedTypes.notPurchaseCompletion]
const kindOfPurchasedTypes = [completedTypes.purchaseCompletion, completedTypes.notPurchaseCompletion]

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  completedType: Yup.number().required(),
  triggerCampaignId: Yup.number()
    .when('completedType', {
      is: completedType => (followingCompletedTypes.includes(completedType)),
      then: Yup.number().required('該当する先行キャンペーンが存在しません'),
    }),
  purchaseHistory: Yup.string()
    .when('completedType', {
      is: completedType => (kindOfPurchasedTypes.includes(completedType)),
      then: Yup.string().required(),
    }),
  customerType: Yup.number().required(),
  gender: Yup.string().required(),
  schedule: Yup.number().required(),
  breakTimeEachCustomer: Yup.number()
    .when('completedType', {
      is: completedType => (Number(completedType) === completedTypes.purchaseCompletion),
      then: Yup.number().required(),
    }),
  stopIfPurchased: Yup.bool(),
  fromName: Yup.string().notIncludeAngleBrackets().required(),
  fromAddress: Yup.string().required(),
  subject: Yup.string().required(),
  previewText: Yup.string(),
  urlParam: Yup.string(),
  htmlBody: Yup.string(),
  textBody: Yup.string(),
  htmlHeader: Yup.string(),
  style: Yup.string(),
})

export const CompletedCampaignForm = ({
  completedCampaign, company, companyCampaignTemplateValue, mailSenderInfo, item, submitAction, dispatch,
}) => {
  const isTestAccount = company.item.testAccount
  const registerCampaignList = completedCampaign.items
    .filter(x => (x.completedType === completedTypes.registerCompletion))
    .map(x => ([x.name, x.id]))
  const purchaseCampaignList = completedCampaign.items
    .filter(x => (x.completedType === completedTypes.purchaseCompletion))
    .map(x => ([x.name, x.id]))
  return (
    <Loading isLoading={completedCampaign.isFetching || company.isFetching || mailSenderInfo.isFetching}>
      <Formik
        initialValues={{
          name: item.name || '',
          completedType: item.completedType || 1,
          triggerCampaignId: item.triggerCampaignId || undefined,
          purchaseHistory: item.purchaseHistory || undefined,
          customerType: item.customerType || 0,
          gender: item.gender || 'all_gender',
          schedule: item.schedule === 0 || item.schedule ? item.schedule : 30,
          breakTimeEachCustomer: item.breakTimeEachCustomer === 0 || item.breakTimeEachCustomer ? item.breakTimeEachCustomer : 24 * 60,
          stopIfPurchased: item.stopIfPurchased || false,
          fromName: item.fromName || '',
          fromAddress: item.fromAddress || '',
          subject: item.subject || '',
          previewText: item.previewText || '',
          urlParam: item.urlParam || '',
          htmlBody: item.htmlBody || '',
          textBody: item.textBody || '',
          htmlHeader: item.htmlHeader || '',
          style: item.style || '',
          testAddress: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (submitAction === 'update') {
            dispatch(completedCampaignAction.submitUpdateCompletedCampaign({ id: item.id, ...values }))
          } else {
            dispatch(completedCampaignAction.submitCreateCompletedCampaign({ ...values, enabled: false }))
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form noValidate>
            { completedCampaign.error && <Alert color="danger">{completedCampaign.error}</Alert> }
            <fieldset>
              <FormGroup>
                <Label require>キャンペーン名称</Label>
                <FieldSingle name="name" type="text" />
              </FormGroup>
              <FormGroup>
                <Label require>ターゲットアクション</Label>
                <FieldSingle
                  type="select"
                  name="completedType"
                  options={ccc.completedTypes}
                  value={() => defaultTargetAction(item.templateTags, values)}
                  disabled={item.stage === 'in_the_middle' || item.stage === 'finished'}
                />
                <FormText>
                  ステップメール（2通目以降）を作成する際は、（後続）が付いたアクションを選択してください。
                </FormText>
              </FormGroup>
              { followingCompletedTypes.includes(Number(values.completedType))
                && (
                  <FormGroup>
                    <Label require>先行キャンペーン</Label>
                    {(() => {
                      let triggerCampaignList
                      switch (Number(values.completedType)) {
                      case completedTypes.notRegisterCompletion:
                        triggerCampaignList = registerCampaignList
                        break
                      case completedTypes.notPurchaseCompletion:
                        triggerCampaignList = purchaseCampaignList
                        break
                      default:
                        triggerCampaignList = []
                      }
                      if (triggerCampaignList.length < 1) {
                        triggerCampaignList.push(['', undefined])
                      }
                      return (<FieldSingle type="select" name="triggerCampaignId" options={triggerCampaignList} />)
                    })()}
                    <FormText>
                      ステップメールの対象となる1通⽬のキャンペーンを選択してください。
                    </FormText>
                  </FormGroup>
                )
              }
            </fieldset>
            <legend className="mb-0">ターゲット顧客属性<HintIcon id="segmentPointTooltip" className="ml-1" style={{ 'font-size': '14px' }} /></legend>
            <UncontrolledTooltip placement="right" target="segmentPointTooltip">
              ️施策に適した傾向を持つ顧客を配信対象に指定することで、成果指標（開封率・クリック率・完了率）を引き上げる効果が期待できます！
            </UncontrolledTooltip>
            <FormText className="mb-3" tag="span">
              セグメント別配信の設定メニューです。
            </FormText>
            <fieldset>
              { kindOfPurchasedTypes.includes(Number(values.completedType))
                && (
                  <FormGroup>
                    <Label require>購入履歴</Label>
                    <FieldSingle type="select" name="purchaseHistory" options={ccc.purchaseHistories} />
                  </FormGroup>
                )
              }
              <FormGroup>
                <Label require>会員種別</Label>
                <FieldSingle type="select" name="customerType" options={ccc.customerTypes} />
              </FormGroup>
              <FormGroup>
                <Label require>性別</Label>
                <FieldSingle type="select" name="gender" options={ccc.gender} />
              </FormGroup>
            </fieldset>
            <legend className="mb-3">配信スケジュール設定</legend>
            <fieldset>
              <FormGroup>
                <Label require>ターゲットアクションからの経過時間</Label>
                <HelpIcon id="scheduleTooltip" className="ml-1" />
                <UncontrolledTooltip placement="right" target="scheduleTooltip">
                  お客さまの⾏動の発⽣後どのくらいの時間にメール配信するかを設定します。
                </UncontrolledTooltip>
                <FieldSingle type="select" name="schedule" options={scheduleTimes(isTestAccount, values.completedType, values.triggerCampaignId, completedCampaign.items)} />
              </FormGroup>
              { Number(values.completedType) === completedTypes.purchaseCompletion
                && (
                  <FormGroup>
                    <Label require>一度メールを送信した顧客への最低配信間隔</Label>
                    <FieldSingle type="select" name="breakTimeEachCustomer" options={ccc.breakTimeEachCustomer} />
                  </FormGroup>
                )
              }
              <FormGroup check>
                <FieldGroup>
                  <Input name="stopIfPurchased" type="checkbox" option="ターゲットアクションからの経過時間までに購入した場合は配信をしない" />
                  <HelpIcon id="stopIfPurchasedTooltip" className="ml-1" />
                  <UncontrolledTooltip placement="right" target="stopIfPurchasedTooltip">
                    購⼊されたお客さまの場合、メールを送るか停⽌するかを設定します。
                  </UncontrolledTooltip>
                </FieldGroup>
              </FormGroup>
            </fieldset>
            <legend className="mb-3">メール設定</legend>
            <fieldset>
              <FormGroup>
                <Label require>差出人名</Label>
                <FieldSingle name="fromName" type="text" />
              </FormGroup>
              <FormGroup>
                <Label require>Reply-toアドレス</Label>
                <HelpIcon id="fromAddressTooltip" className="ml-1" />
                <UncontrolledTooltip placement="right" target="fromAddressTooltip">
                  SPFレコード設定が必須となります。「SPFチェック」ボタンを押すと、入力したアドレスのSPFレコードが正しく設定できているか確認することができます。
                </UncontrolledTooltip>
                <FieldGroup>
                  <Input name="fromAddress" type="text" />
                  <InputGroupAddon addonType="append">
                    <Button
                      color="info"
                      onClick={(e) => { dispatch(spfAction.checkSpf(values.fromAddress)) }}
                    >
SPFチェック
                    </Button>
                  </InputGroupAddon>
                </FieldGroup>
                <FormText>
                  お客様がリタゲメールに返信した場合の返信先メールアドレスです。
                  <Link to="/campaign_common_setting">全体の初期設定はこちら</Link>
                </FormText>
                <FormText>
                  <ManualLink filename="mansys_dns">
                    DNS設定について
                  </ManualLink>
                </FormText>
              </FormGroup>
              <FormGroup>
                <Label require>件名</Label>
                <HintIcon id="subjectPointTooltip" className="ml-1" />
                <UncontrolledTooltip placement="right" target="subjectPointTooltip">
                  15〜30文字がおすすめです。適切に設定することで開封率がアップする可能性があります！
                </UncontrolledTooltip>
                <FieldSingle name="subject" type="text" />
              </FormGroup>
              <FormGroup>
                <Label>プレビューテキスト</Label>
                <HelpIcon id="previewTextTooltip" className="ml-1" />
                <UncontrolledTooltip placement="right" target="previewTextTooltip">
                  メール本⽂の概要（ダイジェスト）として受信箱内に表⽰されるテキストです。<br />
                  ※設定されてない場合、メール本⽂のテキスト情報を先頭から順に読み取り表⽰します。
                </UncontrolledTooltip>
                <HintIcon id="previewTextPointTooltip" className="ml-1" />
                <UncontrolledTooltip placement="right" target="previewTextPointTooltip">
                  統⼀性をもたせ不信感を減らし開封率を引き上げる効果が期待できます！
                </UncontrolledTooltip>
                <FieldSingle name="previewText" type="text" />
              </FormGroup>
              <FormGroup>
                <Label>URLパラメータ</Label>
                <HelpIcon id="urlParamTooltip" className="ml-1" />
                <UncontrolledTooltip placement="right" target="urlParamTooltip">
                  キャンペーン内のリンクに自動で付与するURLパラメータを設定できます。<br />
                  外部のアクセス解析ソフトの計測用パラメータの設定などにご利用ください。
                </UncontrolledTooltip>
                <FieldSingle name="urlParam" type="text" />
              </FormGroup>
            </fieldset>
            <legend className="mb-3">メール内容</legend>
            <fieldset>
              <CampaignHtmlFields
                company={company}
                companyCampaignTemplateValue={companyCampaignTemplateValue}
                formType="completed"
                campaignType={isRegisterOrRegisterFollowing(values.completedType) ? 'register' : 'purchase'}
                submitAction={submitAction}
                values={values}
                setFieldValue={setFieldValue}
                dispatch={dispatch}
              />
              <FormGroup>
                <Label>テキストメール用本文</Label>
                <FieldSingle name="textBody" type="textarea" style={{ minHeight: 200 }} />
                <FormText>
                  <Link to="/campaign_common_setting">テキストパートの配信設定はこちら</Link>
                </FormText>
              </FormGroup>
              <div className="mb-5">
                <Button
                  color="secondary"
                  id="codeHelpToggler2"
                  onClick={() => {}}
                >
                  {' '}
コードのヘルプを表示する
                </Button>
              </div>
              <UncontrolledCollapse toggler="#codeHelpToggler2" className="mb-5">
                <CampaignMailCodeHelp
                  campaignType={isRegisterOrRegisterFollowing(values.completedType) ? 'register' : 'purchase'}
                />
              </UncontrolledCollapse>
              <div className="mb-5">
                <MailPreviewButton
                  color="info"
                  onClickCallback={() => { dispatch(mailPreviewAction.submitCreateMailPreview({ ...values, _type: 'completed' })) }}
                >
メールのプレビュー
                </MailPreviewButton>
              </div>
              <FormGroup>
                <Label>テストメール送信先アドレス</Label>
                <FieldGroup>
                  <Input name="testAddress" type="text" />
                  <InputGroupAddon addonType="append">
                    <Button
                      color="info"
                      onClick={(e) => { dispatch(completedCampaignAction.submitSendCompletedCampaignTestMail(values)) }}
                    >
テストメール送信
                    </Button>
                  </InputGroupAddon>
                </FieldGroup>
                <FormText>カンマ区切りで複数入力可能</FormText>
                <FormText>※メール文面に表示されるアイテム情報などは、ランダムに抽出されたものが表示されます。</FormText>
              </FormGroup>
            </fieldset>
            <div className="text-center"><Button color="primary" type="submit" size="lg">保存</Button></div>
          </Form>
        )}
      </Formik>
    </Loading>
  )
}


/**
 * 配信スケジュールの選択肢設定用メソッド。
 * 先発キャンペーンがある場合は配信スケジュールに制限がかかる。
 * @param  {Boolean} isTestAccount        検証用アカウント判定
 * @param  {Integer} triggerCampaignId    先発キャンペーンID
 * @param  {Array}   allAbandonedCampaign すべての完了系キャンペーン
 * @return {Array}                        表示すべき配信スケジュール一覧
 */

const scheduleTimes = (isTestAccount, completedType, triggerCampaignId, allCompletedCampaign) => {
  let rawSheduleTimes = isTestAccount ? ccc.scheduleTimesForTestAccount : ccc.scheduleTimes
  if (!followingCompletedTypes.includes(Number(completedType)) || !triggerCampaignId) { return rawSheduleTimes }
  const triggerCampaign = allCompletedCampaign.filter(x => (x.id === Number(triggerCampaignId)))[0]
  return rawSheduleTimes.filter(sche => (sche[1] > Number(triggerCampaign.schedule)))
}

const isRegisterOrRegisterFollowing = completedType => (
  [completedTypes.registerCompletion, completedTypes.notRegisterCompletion].includes(Number(completedType))
)

const defaultTargetAction = (templateTags, values) => {
  if (templateTags) {
    const mappingNames = ccc.tagMappingCompletedTypes.map(m => m.name)
    const mappedTag = templateTags.filter(tag => mappingNames.includes(tag.name))[0]
    if (mappedTag) {
      const completedType = ccc.tagMappingCompletedTypes.filter(m => m.name === mappedTag.name)[0].value
      values.completedType = completedType
      return completedType
    }
  }
  return values.completedType
}

const select = ({
  completedCampaign, company, companyCampaignTemplateValue, mailSenderInfo, campaignTemplate,
}, { campaignId, templateId, submitAction }) => {
  let item
  let mailContentOverride = {
    fromAddress: mailSenderInfo.item.replyToEmail,
    fromName: mailSenderInfo.item.fromName,
  }
  let campaignContentOverride
  if (templateId) {
    const template = campaignTemplate.items.filter(obj => (obj.id === templateId))[0] || {}
    campaignContentOverride = {
      subject: template.subject,
      htmlBody: template.htmlBody,
      textBody: template.textBody,
      htmlHeader: template.htmlHeader,
      style: template.style,
      templateTags: template.templateTags,
    }
  }
  if (campaignId) {
    campaignContentOverride = completedCampaign.items.filter(obj => (obj.id === campaignId))[0] || {}
  }
  item = { ...mailContentOverride, ...campaignContentOverride }
  if (submitAction === 'create') {
    item = { ...item, enabled: false, stage: 'in_preparation' }
  }
  return {
    completedCampaign, company, companyCampaignTemplateValue, mailSenderInfo, item, submitAction,
  }
}

export default connect(select)(CompletedCampaignForm)

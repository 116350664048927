import { takeLatest, call, put } from 'redux-saga/effects'
import * as api from 'services/api'
import { sendgridAction } from 'actions'
import { toast } from 'react-toastify'

function* handleRetrieveSuppressionByCode(action) {
  const { payload } = yield call(api.retrieveSuppressionByCode, action.payload)
  yield put(sendgridAction.retrieveSuppressionByCodeDone(payload))
}

const message = 'メール配信システムのステータスを変更しました'
function* handleAddSuppressions(action) {
  const { payload, error } = yield call(api.createSuppressions, action.payload)
  if (error) {
    toast.error(payload.message)
  } else {
    yield put(sendgridAction.createSuppressionsDone(payload))
    toast.success(message)
  }
}

function* handleRemoveSuppression(action) {
  const { payload, error } = yield call(api.removeSuppression, action.payload)
  if (error) {
    toast.error(payload.message)
  } else {
    toast.success(message)
    yield put(sendgridAction.removeSuppressionDone())
  }
}

export const sendgridSaga = [
  takeLatest(sendgridAction.retrieveSuppressionByCode, handleRetrieveSuppressionByCode),
  takeLatest(sendgridAction.createSuppressions, handleAddSuppressions),
  takeLatest(sendgridAction.removeSuppression, handleRemoveSuppression),
]

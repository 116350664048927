import { createActions } from 'redux-actions'

const CHECK_SPF = 'CHECK_SPF'
const CHECK_SPF_DONE = 'CHECK_SPF_DONE'

export const spfAction = createActions(
  {
    [CHECK_SPF_DONE]: [
      (...args) => args[0],
      (...args) => Object.assign({ notification: 'ALL_CASE' }, args[1]),
    ],
  },

  CHECK_SPF,

)

import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Switch } from 'react-router-dom'

import { ConnectedRouter } from 'connected-react-router'
import {
  AdminAuthRoute, AdminUnauthRoute, AuthRoute, UnauthRoute,
} from 'lib/components'
import Base from './layout/Base'
import DashboardPage from './pages/DashboardPage'
import CampaignListPage from './pages/campaign/CampaignListPage'
import CampaignNewPage from './pages/campaign/CampaignNewPage'
import CampaignEditPage from './pages/campaign/CampaignEditPage'
import MailAbTestListPage from './pages/campaign/MailAbTestListPage'
import MailAbTestNewPage from './pages/campaign/MailAbTestNewPage'
import MailAbTestEditPage from './pages/campaign/MailAbTestEditPage'
import CampaignTemplateListPage from './pages/campaign/CampaignTemplateListPage'
import CampaignCommonSettingPage from './pages/campaign/CampaignCommonSettingPage'
import CampaignTemplateValuePage from './pages/campaign/CampaignTemplateValuePage'
import LineSettingPage from './pages/system/LineSettingPage'
import SubscriptionTrackingSettingsPage from './pages/campaign/SubscriptionTrackingSettingsPage'
import LimitDeliverySettingPage from './pages/campaign/LimitDeliverySettingPage'
import SuppressionSettingPage from './pages/campaign/SuppressionSettingPage'
import PopupListPage from './pages/popup/PopupListPage'
import PopupNewPage from './pages/popup/PopupNewPage'
import PopupEditPage from './pages/popup/PopupEditPage'
import ProspectsCampaignListPage from './pages/popup/ProspectsCampaignListPage'
import TransitionReportPage from './pages/report/TransitionReportPage'
import ComparisonReportPage from './pages/report/ComparisonReportPage'
import MailAbTestReportPage from './pages/report/MailAbTestReportPage'
import BehaviorHistoryPage from './pages/siteData/BehaviorHistoryPage'
import BehaviorSummaryPage from './pages/siteData/BehaviorSummaryPage'
import ItemListPage from './pages/siteData/ItemListPage'
import UserSettingPage from './pages/account/UserSettingPage'
import CompanySettingPage from './pages/account/CompanySettingPage'
import RegisteredUserManagementPage from './pages/account/RegisteredUserManagementPage'
import RegisteredUserNewPage from './pages/account/RegisteredUserNewPage'
import MainUserSettingPage from './pages/account/MainUserSettingPage'
import RecoTemplateSettingPage from './pages/account/RecoTemplateSettingPage'
import RecoTemplateNewPage from './pages/account/RecoTemplateNewPage'
import RecoTemplateEditPage from './pages/account/RecoTemplateEditPage'
import TagParameterConfirmationPage from './pages/siteData/TagParameterConfirmationPage'
import CustomerImportPage from './pages/system/CustomerImportPage'
import CustomerImportSettingEditPage from './pages/system/CustomerImportSettingEditPage'
import ItemImportPage from './pages/system/ItemImportPage'
import ItemImportSettingEditPage from './pages/system/ItemImportSettingEditPage'
import TrackingCodePage from './pages/system/TrackingCodePage'
import CampaignHtmlEditorPage from './pages/campaign/CampaignHtmlEditorPage'
import ManualIntroductionPage from './pages/manual/ManualIntroductionPage'
import ManualMailOperationPage from './pages/manual/ManualMailOperationPage'
import LoginPage from './pages/LoginPage'
import SignUpPage from './pages/SignUpPage'
import SignUpCompletePage from './pages/SignUpCompletePage'
import AdminLoginPage from './pages/admin/LoginPage'
import AdminDashBoardPage from './pages/admin/DashboardPage'
import AdminCampaignTemplateListPage from './pages/admin/campaignTemplate/AdminCampaignTemplateListPage'
import AdminCampaignTemplateNewPage from './pages/admin/campaignTemplate/AdminCampaignTemplateNewPage'
import AdminCampaignTemplateEditPage from './pages/admin/campaignTemplate/AdminCampaignTemplateEditPage'
import AdminCompanyListPage from './pages/admin/company/AdminCompanyListPage'
import AdminCompanyDetailPage from './pages/admin/company/AdminCompanyDetailPage'
import AdminTemplateTagListPage from './pages/admin/templateTag/AdminTemplateTagListPage'
import AdminTemplateTagNewPage from './pages/admin/templateTag/AdminTemplateTagNewPage'
import AdminTemplateTagEditPage from './pages/admin/templateTag/AdminTemplateTagEditPage'
import AdminCompanyInvoiceListPage from './pages/admin/company/invoice/AdminCompanyInvoiceListPage'
import AdminCompanyInvoiceTermNewPage from './pages/admin/company/invoice/AdminCompanyInvoiceTermNewPage'
import AdminCompanyInvoiceTermEditPage from './pages/admin/company/invoice/AdminCompanyInvoiceTermEditPage'
import AdminCompanyUnitPriceNewPage from './pages/admin/company/invoice/AdminCompanyUnitPriceNewPage'
import AdminCompanyUnitPriceEditPage from './pages/admin/company/invoice/AdminCompanyUnitPriceEditPage'
import AdminCompanyAllowIpAddressListPage from './pages/admin/company/allowIpAddress/AdminCompanyAllowIpAddressListPage'
import AdminCompanyAllowIpAddressNewPage from './pages/admin/company/allowIpAddress/AdminCompanyAllowIpAddressNewPage'
import AdminCompanyAllowIpAddressEditPage from './pages/admin/company/allowIpAddress/AdminCompanyAllowIpAddressEditPage'

import 'react-toastify/dist/ReactToastify.css'

const publicRoutes = [
  '/login',
  '/sign_up',
  '/sign_up_complete',
  '/admin/login',
  '/campaign_html_editor',
]

const Routes = ({ history }) => (
  <ConnectedRouter history={history}>
    <Base publicRoutes={publicRoutes}>
      <Switch>
        {/* user */}
        <AuthRoute path="/dashboard" component={DashboardPage} />
        <AuthRoute path="/campaign_list" component={CampaignListPage} />
        <AuthRoute path="/campaign_new/:campaign_type" component={CampaignNewPage} />
        <AuthRoute path="/campaign_edit/:campaign_type/:id" component={CampaignEditPage} />
        <AuthRoute path="/mail_ab_test_list" component={MailAbTestListPage} />
        <AuthRoute path="/mail_ab_test_new" component={MailAbTestNewPage} />
        <AuthRoute path="/mail_ab_test_edit/:id" component={MailAbTestEditPage} />
        <AuthRoute path="/campaign_template_list/:campaign_type" component={CampaignTemplateListPage} />
        <AuthRoute path="/campaign_common_setting" component={CampaignCommonSettingPage} />
        <AuthRoute path="/campaign_template_value" component={CampaignTemplateValuePage} />
        <AuthRoute path="/line_setting" component={LineSettingPage} />
        <AuthRoute path="/subscription_tracking_settings" component={SubscriptionTrackingSettingsPage} />
        <AuthRoute path="/limit_delivery_setting" component={LimitDeliverySettingPage} />
        <AuthRoute path="/suppression_new" component={SuppressionSettingPage} />
        <AuthRoute path="/popup_list" component={PopupListPage} />
        <AuthRoute path="/popup_new/" component={PopupNewPage} />
        <AuthRoute path="/popup_edit/:id" component={PopupEditPage} />
        <AuthRoute path="/prospects_campaign_list" component={ProspectsCampaignListPage} />
        <AuthRoute path="/transition_report" component={TransitionReportPage} />
        <AuthRoute path="/comparison_report" component={ComparisonReportPage} />
        <AuthRoute path="/mail_ab_test_report" component={MailAbTestReportPage} />
        <AuthRoute path="/behavior_history" component={BehaviorHistoryPage} />
        <AuthRoute path="/behavior_summary" component={BehaviorSummaryPage} />
        <AuthRoute path="/item_list" component={ItemListPage} />
        <AuthRoute path="/user_setting" component={UserSettingPage} />
        <AuthRoute path="/company_setting" component={CompanySettingPage} />
        <AuthRoute path="/registered_user_management" component={RegisteredUserManagementPage} />
        <AuthRoute path="/registered_user_new" component={RegisteredUserNewPage} />
        <AuthRoute path="/main_user_setting" component={MainUserSettingPage} />
        <AuthRoute path="/reco_template_setting" component={RecoTemplateSettingPage} />
        <AuthRoute path="/reco_template_new" component={RecoTemplateNewPage} />
        <AuthRoute path="/reco_template_edit/:id" component={RecoTemplateEditPage} />
        <AuthRoute path="/tag_parameter_confirmation" component={TagParameterConfirmationPage} />
        <AuthRoute path="/customer_import" component={CustomerImportPage} />
        <AuthRoute path="/customer_import_setting_edit" component={CustomerImportSettingEditPage} />
        <AuthRoute path="/item_import" component={ItemImportPage} />
        <AuthRoute path="/item_import_setting_edit" component={ItemImportSettingEditPage} />
        <AuthRoute path="/tracking_code" component={TrackingCodePage} />
        <AuthRoute path="/manual_introduction" component={ManualIntroductionPage} />
        <AuthRoute path="/manual_mailoperation" component={ManualMailOperationPage} />

        <UnauthRoute path="/login" component={LoginPage} />
        <UnauthRoute path="/sign_up" component={SignUpPage} />
        <UnauthRoute path="/sign_up_complete" component={SignUpCompletePage} />
        <UnauthRoute path="/campaign_html_editor" component={CampaignHtmlEditorPage} />

        {/* admin */}
        <AdminAuthRoute path="/admin/dashboard" component={AdminDashBoardPage} />
        <AdminAuthRoute path="/admin/campaign_template_list" component={AdminCampaignTemplateListPage} />
        <AdminAuthRoute path="/admin/campaign_template_new" component={AdminCampaignTemplateNewPage} />
        <AdminAuthRoute path="/admin/campaign_template_edit/:id" component={AdminCampaignTemplateEditPage} />
        <AdminAuthRoute path="/admin/template_tag_list" component={AdminTemplateTagListPage} />
        <AdminAuthRoute path="/admin/template_tag_new" component={AdminTemplateTagNewPage} />
        <AdminAuthRoute path="/admin/template_tag_edit/:id" component={AdminTemplateTagEditPage} />
        <AdminAuthRoute path="/admin/company_list" component={AdminCompanyListPage} />
        <AdminAuthRoute path="/admin/company_show/:id" component={AdminCompanyDetailPage} />
        <AdminAuthRoute path="/admin/companies/:companyId/invoice_list" component={AdminCompanyInvoiceListPage} />
        <AdminAuthRoute path="/admin/companies/:companyId/invoice_term_new" component={AdminCompanyInvoiceTermNewPage} />
        <AdminAuthRoute path="/admin/companies/:companyId/invoice_term_edit/:id" component={AdminCompanyInvoiceTermEditPage} />
        <AdminAuthRoute path="/admin/companies/:companyId/unit_price_new" component={AdminCompanyUnitPriceNewPage} />
        <AdminAuthRoute path="/admin/companies/:companyId/unit_price_edit/:id" component={AdminCompanyUnitPriceEditPage} />
        <AdminAuthRoute path="/admin/companies/:id/allow_ip_address_list" component={AdminCompanyAllowIpAddressListPage} />
        <AdminAuthRoute path="/admin/companies/:id/allow_ip_address_new" component={AdminCompanyAllowIpAddressNewPage} />
        <AdminAuthRoute path="/admin/companies/:id/allow_ip_address_edit/:allowIpAddressId" component={AdminCompanyAllowIpAddressEditPage} />

        <AdminUnauthRoute path="/admin/login" component={AdminLoginPage} />

        <Redirect to="/dashboard" />
      </Switch>
    </Base>
  </ConnectedRouter>
)

const select = ({ auth }) => ({ auth })

export default connect(select)(Routes)
